<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-app>
    <notification-snackbars-wrapper />
    <v-main>
      <organization-banner />
      <v-app-bar app flat class="v-bar--underline" color="background0">
        <router-link :to="{ name: 'IncidentTable' }" style="text-decoration: none; margin-top: 5px;">
          <img src="/static/unstruct-logo-new.png" width="55" height="55"   />
        </router-link>
        <v-spacer />
        <v-btn color="error--text" :to="{ name: 'report' }" class="mr-10">
          <v-icon left class="mr-3">mdi-domain</v-icon>
          Create IAO
        </v-btn>
      </v-app-bar>
      <v-card class="mx-auto ma-4" max-width="1000" flat outlined>
        <v-card-text>
          <incident-summary-table :items="items" :loading="loading" />
        </v-card-text>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import SearchUtils from "@/search/utils"
import RouterUtils from "@/router/utils"

import IncidentApi from "@/incident/api"
import IncidentSummaryTable from "@/incident/IncidentSummaryTable.vue"
import OrganizationBanner from "@/organization/OrganizationBanner.vue"
import NotificationSnackbarsWrapper from "@/components/NotificationSnackbarsWrapper.vue"

export default {
  name: "IncidentStatus",

  components: {
    IncidentSummaryTable,
    OrganizationBanner,
    NotificationSnackbarsWrapper,
  },

  data() {
    return {
      items: [],
      loading: false,
      filters: { status: ["Active"] },
    }
  },

  created() {
    this.filters = {
      ...this.filters,
      ...RouterUtils.deserializeFilters(this.query),
    }
    this.getActive()
  },

  computed: {
    ...mapFields("route", ["query"]),
  },

  methods: {
    getActive() {
      this.error = null
      this.loading = "error"

      let filterOptions = {
        sortBy: ["reported_at"],
        descending: [true],
        itemsPerPage: -1,
        filters: { ...this.filters },
      }

      filterOptions = SearchUtils.createParametersFromTableOptions({ ...filterOptions })

      IncidentApi.getAll(filterOptions).then((response) => {
        this.items = response.data.items
        this.loading = false
      })
    },
  },
}
</script>
